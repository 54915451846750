var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.showDrawer
        ? _c(
            "v-navigation-drawer",
            {
              attrs: { app: "" },
              model: {
                value: _vm.drawer,
                callback: function($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer"
              }
            },
            [
              _c(
                "v-list",
                { attrs: { dense: "" } },
                _vm._l(_vm.navigationLinks, function(navItem) {
                  return _c(
                    "v-list-item",
                    {
                      key: navItem.link,
                      attrs: { link: "", to: navItem.link }
                    },
                    [
                      navItem.icon
                        ? _c(
                            "v-list-item-action",
                            [_c("v-icon", [_vm._v(_vm._s(navItem.icon))])],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(navItem.text))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showDrawer
        ? _c(
            "v-app-bar",
            { attrs: { app: "", color: "primary", dark: "" } },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  }
                }
              }),
              _c("v-toolbar-title", [_vm._v("GeoViewer Core")]),
              _c("v-spacer"),
              _vm.currentUser !== null
                ? _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.logOut } },
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("v-main", [_c("router-view")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }