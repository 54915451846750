var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-12" }, [
    _c("div", { staticClass: "card card-container" }, [
      _c("img", {
        staticClass: "profile-img-card",
        attrs: {
          id: "profile-img",
          src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        }
      }),
      _c(
        "form",
        {
          attrs: { name: "form" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleRegister.apply(null, arguments)
            }
          }
        },
        [
          !_vm.successful
            ? _c("div", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "username" } }, [
                    _vm._v("Username")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.username,
                        expression: "user.username"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3|max:20",
                        expression: "'required|min:3|max:20'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "username" },
                    domProps: { value: _vm.user.username },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "username", $event.target.value)
                      }
                    }
                  }),
                  _vm.submitted && _vm.errors.has("username")
                    ? _c("div", { staticClass: "alert-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("username")))
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.email,
                        expression: "user.email"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email|max:50",
                        expression: "'required|email|max:50'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "email", name: "email" },
                    domProps: { value: _vm.user.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm.submitted && _vm.errors.has("email")
                    ? _c("div", { staticClass: "alert-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("email")))
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "password" } }, [
                    _vm._v("Password")
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.user.password,
                        expression: "user.password"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:6|max:40",
                        expression: "'required|min:6|max:40'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "password", name: "password" },
                    domProps: { value: _vm.user.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.user, "password", $event.target.value)
                      }
                    }
                  }),
                  _vm.submitted && _vm.errors.has("password")
                    ? _c("div", { staticClass: "alert-danger" }, [
                        _vm._v(_vm._s(_vm.errors.first("password")))
                      ])
                    : _vm._e()
                ]),
                _vm._m(0)
              ])
            : _vm._e()
        ]
      ),
      _vm.message
        ? _c(
            "div",
            {
              staticClass: "alert",
              class: _vm.successful ? "alert-success" : "alert-danger"
            },
            [_vm._v(_vm._s(_vm.message))]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("button", { staticClass: "btn btn-primary btn-block" }, [
        _vm._v("Sign Up")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }