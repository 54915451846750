<template>
  <div class="login-container">
    <v-container>
      <v-row>
        <v-col class="card-container" md="4" offset-md="8">
          <v-card class="pa-5 card" outlined tile>
            <img class="geo-logo" src="@/assets/geo-logo.png" />
            <form name="form" @submit.prevent="handleLogin">
              <div class="form-group mb-5">
                <v-text-field
                  label="Username"
                  v-model="user.username"
                  append-icon="account_box"
                  v-validate="'required'"
                  type="text"
                  class="form-control"
                  name="username"
                  outlined
                  hide-details
                ></v-text-field>
                <div
                  v-if="errors.has('username')"
                  class="alert alert-danger red--text"
                  role="alert"
                >
                  Username is required
                </div>
              </div>
              <div class="form-group mb-5">
                <v-text-field
                  label="Password"
                  v-model="user.password"
                  append-icon="fingerprint"
                  v-validate="'required'"
                  type="password"
                  class="form-control"
                  name="password"
                  outlined
                  hide-details
                ></v-text-field>
                <div
                  v-if="errors.has('password')"
                  class="alert alert-danger red--text"
                  role="alert"
                >
                  Password is required
                </div>
              </div>
              <div class="form-group">
                <v-btn
                  type="submit"
                  depressed
                  x-large
                  block
                  accent
                  color="primary"
                >
                  Login
                  <v-icon>check_circle</v-icon>
                </v-btn>
              </div>
              <div class="form-group">
                <div
                  v-if="message"
                  class="alert alert-danger red--text"
                  role="alert"
                >
                  {{ message }}
                </div>
              </div>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import User from "../models/user";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/profile");
            },
            (error) => {
              this.loading = false;
              this.message =
                error.response?.data?.message ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container {
  max-width: 350px !important;
  // padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.login-container {
  background: url("~@/assets/login-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .geo-logo {
    width: 60%;
    display: block;
    margin: 50px auto;
  }
}
</style>