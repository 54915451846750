<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app v-if="showDrawer">
      <v-list dense>
        <v-list-item
          v-for="navItem in navigationLinks"
          :key="navItem.link"
          link
          :to="navItem.link"
        >
          <v-list-item-action v-if="navItem.icon">
            <v-icon>{{ navItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ navItem.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark v-if="showDrawer">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>GeoViewer Core</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="currentUser !== null" icon @click="logOut">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    showDrawer() {
      return this.$route.name !== "Login";
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }

      return false;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  data: () => ({
    drawer: false,
    navigationLinks: [
      // {
      //   link: "/home",
      //   icon: "mdi-home",
      //   text: "Sign Up",
      // },
      {
        link: "/profile",
        icon: "mdi-contact-mail",
        text: "Profile",
      },
    ],
  }),
};
</script>
