var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "card-container",
                  attrs: { md: "4", "offset-md": "8" }
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-5 card",
                      attrs: { outlined: "", tile: "" }
                    },
                    [
                      _c("img", {
                        staticClass: "geo-logo",
                        attrs: { src: require("@/assets/geo-logo.png") }
                      }),
                      _c(
                        "form",
                        {
                          attrs: { name: "form" },
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handleLogin.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group mb-5" },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  label: "Username",
                                  "append-icon": "account_box",
                                  type: "text",
                                  name: "username",
                                  outlined: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.user.username,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "username", $$v)
                                  },
                                  expression: "user.username"
                                }
                              }),
                              _vm.errors.has("username")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert alert-danger red--text",
                                      attrs: { role: "alert" }
                                    },
                                    [_vm._v(" Username is required ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group mb-5" },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  label: "Password",
                                  "append-icon": "fingerprint",
                                  type: "password",
                                  name: "password",
                                  outlined: "",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.user.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.user, "password", $$v)
                                  },
                                  expression: "user.password"
                                }
                              }),
                              _vm.errors.has("password")
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert alert-danger red--text",
                                      attrs: { role: "alert" }
                                    },
                                    [_vm._v(" Password is required ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    type: "submit",
                                    depressed: "",
                                    "x-large": "",
                                    block: "",
                                    accent: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(" Login "),
                                  _c("v-icon", [_vm._v("check_circle")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "form-group" }, [
                            _vm.message
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-danger red--text",
                                    attrs: { role: "alert" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }