import axios from 'axios';

const ENDPOINT_BASE_URL = `${process.env.VUE_APP_API_URL}utenti`;

class AuthService {
    login(user) {
        return axios
            .post(ENDPOINT_BASE_URL + '/authenticate', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(ENDPOINT_BASE_URL + '/signup', {
            username: user.username,
            email: user.email,
            password: user.password
        });
    }
}

export default new AuthService();