import { normalize, schema } from "normalizr";
import ConfigUserService from '../services/configuser.service';

const configUserEntity = new schema.Entity(
    "configsUser",
    {},
    {
        idAttribute: value => "" + value.utenteFK + value.ruoloFK + value.applicazioneFK + value.configFK
    }
);

const initialState = {
    entities: [],
    ids: [],
    currentId: null
}

export const configuser = {
    namespaced: true,
    state: initialState,
    getters: {
        find: state => id => {
            return state.entities.configsUser[id];
        },
        list: (state, getters) => {
            return state.ids.map(id => getters.find(id));
        },
        current: (state, getters) => {
            return state.currentId !== null ? getters.find(state.currentId) : null;
        }
    },
    actions: {
        async loadListConfigsUser({ commit, getters }, user) {
            let data = null;
            try {
                data = await ConfigUserService.getConfigUtente(user.id);

                let normalizedData = normalize(data, [configUserEntity]);

                commit("setNormalizedData", normalizedData);

                console.log("getters::" + getters.list.map(configUser=>configUser.utenteFK+"_"+ configUser.ruoloFK+"_"+configUser.applicazioneFK+"_"+configUser.configFK))

                // console.log('elencoConfigUser', elencoConfigUser);
                // commit('loadConfigsSuccess', elencoConfigUser);
            }
            catch (error) {
                return error;
            }
            console.log("DATA length:: " + data.length);

            return data;
        },
    },
    mutations: {
        setNormalizedData(state, normalizedData) {
            state.entities = normalizedData.entities;
            state.ids = normalizedData.result;
        },
        setCurrentId(state, currentId) {
            state.currentId = currentId;
        }
    }
}