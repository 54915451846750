import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "md"
    },
    theme: {
        themes: {
            light: {
                primary: '#F49C0E', // #E53935
            },
        },
    },

});