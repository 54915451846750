import axios from 'axios';
import authHeader from './auth-header';
const ENDPOINT_BASE_URL = `${process.env.VUE_APP_API_URL}`;

class ConfigUserService {
    getConfigUtente(userId) {
        return axios.get(ENDPOINT_BASE_URL + 'ConfigurazioniUtenti?idUtente=' +
            userId, { headers: authHeader() })
            .then(res => res.data);
    }



}

export default new ConfigUserService();